<app-default-layout>
    <div class="text-center">
        <h1 class="text-error">404</h1>
        <h3 class="mt-3 mb-2">Page not Found</h3>
        <p class="text-muted mb-3">It's looking like you may have taken a wrong turn. Don't worry... it happens to
            the best of us. You might want to check your internet connection. Here's a little tip that might
            help you get back on track.</p>
        <a routerLink="/" class="btn btn-danger waves-effect waves-light"><i class="fas fa-home me-1"></i> Back to
            Home</a>
    </div>

</app-default-layout>