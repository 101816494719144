<div class="topnav">
    <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
            <div class="navbar-collapse" id="topnav-menu-content" #collapse="ngbCollapse"
                [(ngbCollapse)]="!showMobileMenu">
                <ul class="navbar-nav">

                    <ng-container *ngFor="let menu of menuItems;let i = index">
                        <!-- menu item without any child -->
                        <ng-container *ngIf="!hasSubmenu(menu)">
                            <ng-container
                                *ngTemplateOutlet="MenuItem;context:{menu: menu, className: 'nav-item', linkClassName: 'nav-link nav-link-ref'}">
                            </ng-container>
                        </ng-container>

                        <!-- menu item with child -->
                        <ng-container *ngIf="hasSubmenu(menu)">
                            <ng-container
                                *ngTemplateOutlet="MenuItemWithChildren;context:{menu: menu,className:'nav-item dropdown',linkClassName: 'dropdown-toggle nav-link arrow-none nav-link-ref'}">
                            </ng-container>
                        </ng-container>
                    </ng-container>

                </ul> <!-- end navbar-->
            </div> <!-- end .collapsed-->
        </nav>
    </div> <!-- end container-fluid -->
</div> <!-- end topnav-->

<!-- Reusable Templates -->
<ng-template #MenuItemWithChildren let-menu="menu" let-className="className" let-linkClassName="linkClassName">
    <li [class]="className" [ngClass]="{'active': activeMenuItems.includes(menu.key)}" ngbDropdown>
        <a [class]="linkClassName" [ngClass]="{'active': activeMenuItems.includes(menu.key)}"
            href="javascript: void(0);" role="button" id="topnav-{{menu.key}}" attr.aria-haspopup="true"
            [attr.aria-expanded]="!menu.collapsed" [attr.data-menu-key]="menu.key" (click)="toggleMenuItem(menu)">
            <i class="{{ menu.icon }} me-1" *ngIf="menu.icon"></i>
            {{ menu.label }} <div class="arrow-down"></div>
        </a>

        <ng-container *ngIf="!hasGrandChildren(menu) && menu.children.length >=10">
            <div class="dropdown-menu mega-dropdown-menu dropdown-mega-menu-xl" [ngClass]="{'show':!menu.collapsed}"
                attr.aria-labelledby="topnav-{{menu.key}}">
                <ng-container *ngTemplateOutlet="MegaMenu;context:{menu:menu.children}">
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="!hasGrandChildren(menu) && menu.children.length < 10">
            <div class="dropdown-menu" [ngClass]="{'show':menu.collapsed}" attr.aria-labelledby="topnav-{{menu.key}}">
                <ng-container *ngFor="let subitem of menu.children">
                    <ng-container
                        *ngTemplateOutlet="SubMenu;context:{menu: subitem,linkClassName: 'dropdown-toggle dropdown-item arrow-none nav-link-ref'}">
                    </ng-container>

                </ng-container>
            </div>
        </ng-container>


        <div class="dropdown-menu" [ngClass]="{'show':!menu.collapsed}" attr.aria-labelledby="topnav-{{menu.key}}"
            *ngIf="hasGrandChildren(menu)">
            <ng-container *ngFor=" let subitem of menu.children">
                <ng-container
                    *ngTemplateOutlet="SubMenu;context:{menu: subitem,linkClassName: 'dropdown-toggle dropdown-item arrow-none nav-link-ref'}">
                </ng-container>

            </ng-container>
        </div>
    </li>
</ng-template>

<ng-template #MegaMenu let-menu="menu">
    <div class="row">
        <div class="col-lg-4" *ngFor="let menuItem of menu">
            <a [routerLink]="menuItem.url" class="dropdown-item nav-link-ref"
                [ngClass]="{'active': activeMenuItems.includes(menuItem.key)}" [attr.data-menu-key]="menuItem.key">
                {{ menuItem.label }}
            </a>
        </div>
    </div>
</ng-template>

<ng-template #SubMenu let-menu="menu" let-linkClassName="linkClassName">
    <div class="dropdown" *ngIf="hasSubmenu(menu)">
        <a [class]="linkClassName" [ngClass]="{'active': activeMenuItems.includes(menu.key)}"
            href="javascript: void(0);" id="topnav-{{menu.key}}" role="button" [attr.data-menu-key]="menu.key"
            (click)="toggleMenuItem(menu)">
            {{ menu.label }} <div class="arrow-down"></div>
        </a>

        <div class="dropdown-menu" [ngClass]="{'show':menu.collapsed}" attr.aria-labelledby="topnav-{{menu.key}}">
            <ng-container *ngFor="let subitem of menu.children">

                <ng-container
                    *ngTemplateOutlet="SubMenu;context:{menu: subitem,linkClassName: 'dropdown-item arrow-none nav-link-ref'}">
                </ng-container>
            </ng-container>
        </div>
    </div>

    <a [routerLink]="menu.url" class="dropdown-item nav-link-ref"
        [ngClass]="{'active': activeMenuItems.includes(menu.key)}" [attr.data-menu-key]="menu.key"
        *ngIf="!hasSubmenu(menu)">{{ menu.label }} </a>
</ng-template>

<ng-template #MenuItem let-menu="menu" let-className="className" let-linkClassName="linkClassName">
    <li [class]="className" [ngClass]="{'active': activeMenuItems.includes(menu.key)}">
        <a [routerLink]="menu.url" [class]="linkClassName" [ngClass]="{'active': activeMenuItems.includes(menu.key)}"
            [attr.data-menu-key]="menu.key">
            <i class="{{ menu.icon }} me-1" *ngIf="menu.icon"></i>
            <span> {{ menu.label }}</span>
        </a>
    </li>
</ng-template>