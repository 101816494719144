<app-default-layout>
    <div class="text-center">
        <h1 class="text-error">500</h1>
        <h3 class="mt-3 mb-2">Internal Server Error</h3>
        <p class="text-muted mb-3">Why not try refreshing your page? or you can contact <a [routerLink]="['.']"
                class="text-dark"><b>Support</b></a></p>
        <a routerLink="/" class="btn btn-danger waves-effect waves-light"><i class="fas fa-home me-1"></i> Back to
            Home</a>
    </div>

</app-default-layout>