const URL_API = 'https://citytransporte.rockapps.top';
//const URL_API = 'http://localhost:3001';
const URL_API_GPS_SERVICE = 'https://178.63.27.106';

export const environment = {
  production: true,
  GOOGLE_MAPS_API_KEY: 'AIzaSyAkkcRDMUQDrr6PK5mMXqgj7u2lmoHIQuM',
  APP_VERSION : "1.2.0",
  services:{
    api :`${URL_API}`,
    GpsServiceApi : `${URL_API_GPS_SERVICE}`
  }
};
