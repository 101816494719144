import { MenuItem } from '../models/menu.model';
import { Roles } from 'src/app/core/helpers/roles.utils';

// menu items for vertcal and detached layout

const MENU_ITEMS: MenuItem[] = [
  { key: 'navigation', label: 'Navigation', isTitle: true },
  {
    key: 'Vigia',
    label: 'Vigia',
    isTitle: false,
    icon: 'mdi mdi-calendar-clock-outline',
    role: ['SuperAdmin', 'Administrador', 'Administrador Conductor'],

    //badge: { variant: 'success', text: '9+' },
    url: '/semaforo',
  },
  {
    key: 'dashboard',
    label: 'Dashboard',
    isTitle: false,
    icon: 'mdi mdi-view-dashboard-outline',
    //badge: { variant: 'success', text: '9+' },
    url: '/dashboard',
  },

  {
    key: 'AdminEmpresas',
    label: 'Admin Empresas',
    isTitle: true,
    role: ['SuperAdmin'],
  },
  {
    key: 'apps-empresas',
    label: 'Empresas',
    isTitle: false,
    icon: 'mdi mdi-domain',
    collapsed: true,
    role: ['SuperAdmin'],
    children: [
      {
        key: 'empresas-list',
        label: 'Lista de Empresas',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: '/empresas/list',
        role: ['SuperAdmin'],
        parentKey: 'apps-empresas',
      },
      {
        key: 'empresas-created',
        label: 'Crear Empresa',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/empresas/created',
        role: ['SuperAdmin'],
        parentKey: 'apps-empresas',
      },
    ],
  },
  {
    key: 'apps-preoperacionalTitle',
    label: 'Revisiones Diarias',
    isTitle: true,
    role: [
      'Administrador',
      'SuperAdmin',
      'Administrador Conductor',
      'Conductor',
      'Conductor Fuec',
    ],
  },
  {
    key: 'apps-preoperacional',
    label: 'Revisiones Diarias',
    isTitle: false,
    icon: 'mdi mdi-format-list-bulleted',
    collapsed: true,
    role: [
      'Administrador',
      'SuperAdmin',
      'Administrador Conductor',
      'Conductor',
    ],
    children: [
      {
        key: 'revisionDiaria-list',
        label: 'Listado de revisiones diarias',
        isTitle: false,
        url: 'vehiculos/revisiones',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-preoperacional',
      },
      {
        key: 'preoperacional',
        label: 'Revisión Diaria',
        isTitle: false,
        url: '/preoperacional/inicio',
        role: [
          'Conductor',
          'Administrador Conductor',
          'SuperAdmin',
          'Administrador',
          'Conductor Fuec Preoperacional',
        ],
        parentKey: 'apps-preoperacional',
      },
    ],
  },
  {
    key: 'AdminClientes',
    label: 'Admin Clientes',
    isTitle: true,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
  },
  {
    key: 'apps-clientes',
    label: 'Clientes',
    isTitle: false,
    icon: 'mdi mdi-account-box-multiple-outline',
    collapsed: true,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
    children: [
      {
        key: 'clientes-list',
        label: 'Lista de clientes',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: '/clientes/list',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-clientes',
      },
      {
        key: 'clientes-created',
        label: 'Crear Cliente',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/clientes/created',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-clientes',
      },
    ],
  },

  {
    key: 'AdminUsuarios',
    label: 'Admin Usuarios',
    isTitle: true,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
  },
  {
    key: 'apps-usuarios',
    label: 'Usuarios',
    isTitle: false,
    icon: 'mdi mdi-account',
    collapsed: true,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
    children: [
      {
        key: 'usuarios-list',
        label: 'Lista de usuarios',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: '/usuarios/list',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-usuarios',
      },
      {
        key: 'Usuarios-created',
        label: 'Crear Usuarios',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/usuarios/created',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-usuarios',
      },
    ],
  },
  {
    key: 'AdminFuec',
    label: 'Admin FUEC',
    isTitle: true,
    role: [
      'Administrador',
      'SuperAdmin',
      'Administrador Conductor',
      'Conductor Fuec',
    ],
  },
  {
    key: 'apps-fuec',
    label: 'FUEC',
    isTitle: false,
    icon: 'mdi mdi-file-document',
    collapsed: true,
    role: [
      'Administrador',
      'SuperAdmin',
      'Administrador Conductor',
      'Conductor Fuec',
    ],
    children: [
      {
        key: 'fuec-list',
        label: 'Lista de FUEC',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: '/fuec/list',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-fuec',
      },
      {
        key: 'fuec-createPermanente',
        label: 'Crear FUEC Permanente',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/fuec/createPermanente',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-fuec',
      },
      {
        key: 'fuec-createOcasional',
        label: 'Crear FUEC Ocasional',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/fuec/createOcasional',
        role: [
          'Administrador',
          'SuperAdmin',
          'Administrador Conductor',
          'Conductor Fuec',
        ],
        parentKey: 'apps-fuec',
      },
    ],
  },
  {
    key: 'AdminCartas',
    label: 'Admin documentos',
    isTitle: true,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
  },
  {
    key: 'apps-Cartas',
    label: 'Cartas-Contratos',
    isTitle: false,
    icon: 'mdi mdi-file',
    collapsed: true,
    role: [
      'Administrador',
      'SuperAdmin',
      'Administrador Conductor',
      'Administrador Vehiculos',
    ],
    children: [
      {
        key: 'vehiculos-cartas-contratos',
        label: 'Crear carta-contrato',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/cartas-contratos/create',
        role: [
          'Administrador',
          'SuperAdmin',
          'Administrador Conductor',
          'Administrador Vehiculos',
        ],
        parentKey: 'apps-Cartas',
      },
      {
        key: 'vehiculos-cartas-contratos',
        label: 'Lista de documentos',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/cartas-contratos/list',
        role: [
          'Administrador',
          'SuperAdmin',
          'Administrador Conductor',
          'Administrador Vehiculos',
        ],
        parentKey: 'apps-Cartas',
      },
    ],
  },
  {
    key: 'AdminVehiculos',
    label: 'Admin Vehiculos',
    isTitle: true,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
  },
  {
    key: 'apps-vehiculos',
    label: 'Vehiculos',
    isTitle: false,
    icon: 'mdi mdi-car',
    collapsed: true,
    role: [
      'Administrador',
      'SuperAdmin',
      'Administrador Conductor',
      'Administrador Vehiculos',
    ],
    children: [
      {
        key: 'vehiculos-list',
        label: 'Lista de vehiculos',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: '/vehiculos/list',
        role: [
          'Administrador',
          'SuperAdmin',
          'Administrador Conductor',
          'Administrador Vehiculos',
        ],
        parentKey: 'apps-vehiculos',
      },
      {
        key: 'vehiculos-created',
        label: 'Crear Vehiculo',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/vehiculos/created',
        role: [
          'Administrador',
          'SuperAdmin',
          'Administrador Conductor',
          'Administrador Vehiculos',
        ],
        parentKey: 'apps-vehiculos',
      },

      // {
      //   key: 'apps-Inspeccion',
      //   label: 'Inspecciones',
      //   isTitle: false,
      //   icon: 'mdi mdi-eye',
      //   parentKey: 'apps-vehiculos',
      //   collapsed: true,
      //   role: [
      //     'Administrador',
      //     'SuperAdmin',
      //     'Administrador Conductor',
      //     'Administrador Vehiculos',
      //   ],
      //   children: [
      //     {
      //       key: 'inspecciones-list',
      //       label: 'Lista de inspecciones',
      //       isTitle: false,
      //       //icon: 'mdi mdi-account-box-multiple-outline',
      //       url: '/inspeccion/list',
      //       role: [
      //         'Administrador',
      //         'SuperAdmin',
      //         'Administrador Conductor',
      //         'Administrador Vehiculos',
      //       ],
      //       parentKey: 'apps-Inspeccion',
      //     },
      //     {
      //       key: 'inspecciones-created',
      //       label: 'Crear inspeccion',
      //       isTitle: false,
      //       //icon: 'mdi mdi-account-multiple-plus-outline',
      //       url: '/inspeccion/create',
      //       role: [
      //         'Administrador',
      //         'SuperAdmin',
      //         'Administrador Conductor',
      //         'Administrador Vehiculos',
      //       ],
      //       parentKey: 'apps-Inspeccion',
      //     },
      //   ],
      // },
      {
        key: 'apps-Mantenimientos',
        label: 'Mantenimientos',
        isTitle: false,
        icon: 'mdi mdi-wrench',
        parentKey: 'apps-vehiculos',
        collapsed: true,
        role: [
          'Administrador',
          'SuperAdmin',
          'Administrador Conductor',
          'Administrador Vehiculos',
        ],
        children: [
          {
            key: 'mantenimientos-created',
            label: 'Crear mantenimiento',
            isTitle: false,
            //icon: 'mdi mdi-account-multiple-plus-outline',
            url: '/mantenimientos/create',
            role: [
              'Administrador',
              'SuperAdmin',
              'Administrador Conductor',
              'Administrador Vehiculos',
            ],
            parentKey: 'apps-mantenimientos',
          },
          {
            key: 'mantenimientos-list',
            label: 'Lista de Mantenimientos',
            isTitle: false,
            //icon: 'mdi mdi-account-box-multiple-outline',
            url: '/mantenimientos/list',
            role: [
              'Administrador',
              'SuperAdmin',
              'Administrador Conductor',
              'Administrador Vehiculos',
            ],
            parentKey: 'apps-mantenimientos',
          },

          // {
          //   key: 'mantenimientos-control',
          //   label: 'Control de Mantenimientos',
          //   isTitle: false,
          //   //icon: 'mdi mdi-account-box-multiple-outline',
          //   url: '/mantenimientos/control',
          //   role: [
          //     'Administrador',
          //     'SuperAdmin',
          //     'Administrador Conductor',
          //     'Administrador Vehiculos',
          //   ],
          //   parentKey: 'apps-mantenimientos',
          // },
        ],
      },
    ],
  },
];

const HORIZONTAL_MENU_ITEMS: MenuItem[] = [
  {
    key: 'Vigia',
    label: 'Vigia',
    isTitle: false,
    icon: 'mdi mdi-calendar-clock-outline',
    role: ['SuperAdmin', 'Administrador', 'Administrador Conductor'],
    //badge: { variant: 'success', text: '9+' },
    url: '/semaforo',
  },
  {
    key: 'dashboard',
    label: 'Dashboard',
    isTitle: false,
    icon: 'mdi mdi-view-dashboard',
    url: '/dashboard',
  },
  {
    key: 'apps-empresas',
    label: 'Empresas',
    isTitle: true,
    icon: 'mdi mdi-domain',
    collapsed: false,
    role: ['SuperAdmin'],
    children: [
      {
        key: 'empresas-list',
        label: 'Lista de Empresas',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: '/empresas/list',
        role: ['SuperAdmin'],
        parentKey: 'apps-empresas',
      },
      {
        key: 'empresas-created',
        label: 'Crear Empresa',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/empresas/created',
        role: ['SuperAdmin'],
        parentKey: 'apps-empresas',
      },
    ],
  },
  {
    key: 'apps-preoperacional',
    label: 'Revisiones Diarias',
    isTitle: true,
    icon: 'mdi mdi-format-list-bulleted',
    collapsed: false,
    role: [
      'Administrador',
      'SuperAdmin',
      'Administrador Conductor',
      'Conductor',
      'Conductor Fuec',
    ],
    children: [
      {
        key: 'revisionDiaria-list',
        label: 'Listado de revisiones diarias',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: 'vehiculos/revisiones',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-clientes',
      },
      {
        key: 'PreOperacional',
        label: 'Revision diaria',
        isTitle: false,
        url: '/Preoperacional/Inicio',
        role: [
          'Conductor',
          'Administrador Conductor',
          'SuperAdmin',
          'Conductor Fuec',
        ],
      },
    ],
  },
  {
    key: 'apps-clientes',
    label: 'Clientes',
    isTitle: true,
    icon: 'mdi mdi-account-box-multiple-outline',
    collapsed: false,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
    children: [
      {
        key: 'clientes-list',
        label: 'Lista de clientes',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: '/clientes/list',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-clientes',
      },
      {
        key: 'clientes-created',
        label: 'Crear Cliente',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/clientes/created',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-clientes',
      },
    ],
  },
  {
    key: 'apps-usuarios',
    label: 'Usuarios',
    isTitle: true,
    icon: 'mdi mdi-account',
    collapsed: false,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
    children: [
      {
        key: 'usuarios-list',
        label: 'Lista de Usuarios',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: '/usuarios/list',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-usuarios',
      },
      {
        key: 'usuarios-created',
        label: 'Crear Usuarios',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/usuarios/created',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-usuarios',
      },
    ],
  },
  {
    key: 'apps-fuec',
    label: 'FUEC',
    isTitle: false,
    icon: 'mdi mdi-file-document',
    collapsed: true,
    role: [
      'Administrador',
      'SuperAdmin',
      'Administrador Conductor',
      'Conductor Fuec',
    ],
    children: [
      {
        key: 'fuec-list',
        label: 'Lista de FUEC',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: '/fuec/list',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-fuec',
      },
      {
        key: 'fuec-createOcasional',
        label: 'Crear FUEC Ocasional',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/fuec/createOcasional',
        role: [
          'Administrador',
          'SuperAdmin',
          'Administrador Conductor',
          'Conductor Fuec',
        ],
        parentKey: 'apps-fuec',
      },
      {
        key: 'fuec-createPermanente',
        label: 'Crear FUEC Permanente',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/fuec/createPermanente',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-fuec',
      },
    ],
  },

  {
    key: 'AdminCartas',
    label: 'Admin documentos',
    isTitle: true,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
  },
  {
    key: 'apps-Cartas',
    label: 'Cartas-Contratos',
    isTitle: false,
    icon: 'mdi mdi-file',
    collapsed: true,
    role: [
      'Administrador',
      'SuperAdmin',
      'Administrador Conductor',
      'Administrador Vehiculos',
    ],
    children: [
      {
        key: 'vehiculos-cartas-contratos',
        label: 'Crear carta-contrato',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/cartas-contratos/create',
        role: [
          'Administrador',
          'SuperAdmin',
          'Administrador Conductor',
          'Administrador Vehiculos',
        ],
        parentKey: 'apps-Cartas',
      },
      {
        key: 'vehiculos-cartas-contratos',
        label: 'Lista de documentos',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/cartas-contratos/list',
        role: [
          'Administrador',
          'SuperAdmin',
          'Administrador Conductor',
          'Administrador Vehiculos',
        ],
        parentKey: 'apps-Cartas',
      },
    ],
  },
  {
    key: 'AdminVehiculos',
    label: 'Admin Vehiculos',
    isTitle: true,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
  },
  {
    key: 'apps-vehiculos',
    label: 'Vehiculos',
    isTitle: true,
    icon: 'mdi mdi-car',
    collapsed: false,
    role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
    children: [
      {
        key: 'vehiculos-list',
        label: 'Lista de Vehiculos',
        isTitle: false,
        //icon: 'mdi mdi-account-box-multiple-outline',
        url: '/vehiculos/list',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-vehiculos',
      },
      {
        key: 'vehiculos-created',
        label: 'Crear Vehiculo',
        isTitle: false,
        //icon: 'mdi mdi-account-multiple-plus-outline',
        url: '/vehiculos/created',
        role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
        parentKey: 'apps-vehiculos',
      },
      // {
      //   key: 'apps-Inspeccion',
      //   label: 'Inspecciones',
      //   isTitle: false,
      //   icon: 'mdi mdi-list',
      //   collapsed: true,
      //   role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
      //   children: [
      //     {
      //       key: 'inspecciones-list',
      //       label: 'Lista de inspecciones',
      //       isTitle: false,
      //       //icon: 'mdi mdi-account-box-multiple-outline',
      //       url: '/inspeccion/list',
      //       role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
      //       parentKey: 'apps-Inspeccion',
      //     },
      //     {
      //       key: 'inspecciones-created',
      //       label: 'Crear inspeccion',
      //       isTitle: false,
      //       //icon: 'mdi mdi-account-multiple-plus-outline',
      //       url: '/inspeccion/create',
      //       role: ['Administrador', 'SuperAdmin', 'Administrador Conductor'],
      //       parentKey: 'apps-Inspeccion',
      //     },
      // ],
      // },
    ],
  },
];

export { MENU_ITEMS, HORIZONTAL_MENU_ITEMS };
